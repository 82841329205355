export default {
	title: '網站名稱',
	fuzhi:'复制',
	zongjine:'總計',
	h_title: {
		shouye: '首頁',
		login: '登錄',
		hudong: '活動中心',
		xianshangkefu: '線上客服',
		chongzhi: '充值',
		erduzhuanhuan: '額度轉換',
		erduzhuanhuanTow: "虛擬貨幣充值",
		gerenzhongxin: '個人中心',
		gerenxinxi: '個人信息',
		bangdingshouji: '綁定手機',
		dailizhongxin: '代理中心',
		xiaoxi: '消息',
		tixian: '提現',
		baobiao_cunkuang: '存款記錄',
		baobiao_tixian: '提現記錄',
		baobiao_touzhu: '投註記錄',
		baobiao_fanshui: '返水記錄',
		baobiao_eduzhuanhuan: '額度轉換記錄',
		zhuce: '註冊',
		xiugaixhifumima: '修改支付密碼',
		huodongxiangqing: '活動詳情',
		zizhufuwu: '自助服務',
		zizhufuwu_list: '幫助中心',
		zizhufuwu_list2: '下分教程',
		zizhufuwu_list3: '上分教程',
		msg: '通知公告'
	},
	newList: {
		title1: '無記錄',
		title2: '充值',
		title3: '轉數快',
		title4: '便利商店增值',
		title5: '虛擬幣支付',
		title6: '支付寶掃碼充值',
		title7: '消費卷支付',
		title8: '充值金額：',
		title9: '請輸入充值金額',
		title10: '付款姓名：',
		title11: '請輸入付款姓名',
		title12: '充值贈送：',
		title13: '無送分儲值（2倍流水）',
		title14: '無限儲值贈送5%（10倍流水）',
		title15: '每日首筆儲值贈送20%（最高送2000，20倍码流水）',
		title152: '每日第二笔储值赠送5%（最高送2000，10倍码流水）',
		title16: '充值方式：',
		title17: '轉數快',
		title18: '便利商店增值',
		title19: '虛擬幣支付',
		title20: '支付寶掃碼充值',
		title21: '獲取收款銀行資訊',
		title22: '返回重新輸入',
		title23: ' * 註意：匯款需根據系統顯示尾款進行匯款',
		title24: '不接受匯款方式：',
		title25: 'A 現金存款',
		title26: 'B ATM存款',
		title27: 'C 支票存款',
		title28: 'D 匯款額度少於100',
		title29: 'E 擅自進款，匯款金額與提交金額不匹配',
		title30: 'F 系統名字與銀行名字不匹配',
		title31: '發生以上條例規定情況，一律不處理。',
		title32: '任何情況引起的誤差，本公司概不負責，敬請體諒',
		title33: '收款銀行',
		title34: '請記錄以下收款資訊,並匯款',
		title35: '收款帳號：',
		title36: '收款帳戶名：',
		title37: '收款二維碼：',
		title38: '充值地址(TRC)：',
		title39: '收款二維碼：',
		title40: '返回重新輸入',
		title41: '確認充值',
		title42: '註：為了可以快速上分,請按照系統提示金額進行轉帳,',
		title43: '轉帳金額精確到小數點後兩位,如不是按照提示金額轉帳系統會自動拒絕該轉帳請求',
		title44: '並且不做進行補分操作！ 謝謝',
		title45: '複製成功！',
		title46: '請輸入充值金額',
		title47: '請輸入付款姓名',
		title48: '確認已經轉帳到指定帳戶,申請上分？',
		title49: '確認',
		title50: '網絡異常',
		title51: '賬戶號碼：',
		title52: '轉速快',
		title53: 'USDT',
		title54: '中國工商銀行',
		title55: '中國建設銀行',
		title56: '中國農業銀行',
		title57: '中國銀行',
		title58: '中國交通銀行',
		title59: '招商銀行',
		title60: '廣發銀行',
		title61: '平安銀行',
		title62: '浦發銀行',
		title63: '民生銀行',
		title64: '中國郵政儲蓄銀行',
		title65: '華夏銀行',
		title66: '深圳發展銀行',
		title67: '中信銀行',
		title68: '興業銀行',
		title69: '光大銀行',
		title70: '渣打銀行',
		title71: '匯豐銀行',
		title72: '中興銀行',
		title73: '恒生銀行',
		title74: '創新銀行',
		title75: '請輸入賬戶名稱',
		title76: '取款金額HKD：',
		title77: '最低500',
		title78: '提交 CLICK',
		title79: '備註：',
		title80: '如提現出現打碼量不足,而您的流水已經超過了充值金額的八倍,請您稍後再來提現,因為遊戲方下註數據沒有返還到本現金站,造成您的流水不能及時更新,造成的不便請您諒解,或者聯系線上客服！',
		title81: '請選擇提現通道',
		title82: '姓名',
		title83: '收款賬號',
		title84: '提款金額',
		title85: '提款密碼',
		title86: '銀行卡管理',
		title87: '新增銀行戶口',
		title88: '戶口號碼',
		title89: '收款姓名',
		title90: '狀態',
		title91: '綁定',
		title92: '銀行卡',
		title93: '轉數快',
		title94: '轉數快ID',
		title95: '測試',
		title96: '正常',
		title97: '是',
		title98: '請玩家在註冊的時候姓名務必要填寫與自己的銀行戶口一樣，因為姓名與戶口不匹配，我方系統將無法出款給玩家。本公司將有權利一概不負責，如有不便敬請見諒！',
		title99: '類型：',
		title100: '收款姓名：',
		title101: '請輸入收款姓名',
		title102: '銀行名稱：',
		title103: '所屬銀行：',
		title104: '收款賬號：',
		title105: '請輸入收款賬號',
		title106: '取消',
		title107: '請輸入姓名',
		title108: '請選擇銀行名稱',
		title109: '請輸入轉數快銀行名稱',
		title110: '請輸入姓名收款帳號',
		title111: '請輸入地址',
		title112: '請輸入轉數快所屬銀行',
		title113: '交易歷史',
		title114: '種類',
		title115: '存款',
		title116: '提現',
		title117: '投註',
		title118: '返水',
		title119: '額度轉換',
		title120: '開始日期',
		title121: '選擇開始時間',
		title122: '結束日期',
		title123: '選擇結束日期',
		title124: '提交 CLICK',
		title125: '創建時間',
		title126: '狀態',
		title127: '種類',
		title128: '金額',
		title129: '成功',
		title130: '元',
		title131: '顯示第',
		title132: '至',
		title133: '項結果共',
		title134: '項',
		title135: '上頁',
		title136: '下頁',
		title137: '原因',
	},
	newdasf: {
		title: ["下 載", "我的余額", "最低充值", "最低提款"]
	},
	kefuzhongxin: {
		title: '歡迎來到客服中心',
		titleTow: '24小時隨時為您服務',
		zaixiankefu: '在線客服',
		Skype: 'Skype',
		Telegram: 'Telegram',
		Whatsapp: 'Whatsapp',
	},
	common: {
		shuaxin: '刷新成功',
		weihu: '正在維護中...',
		gengduo: '更多',
		xiangxi: '詳情',
		tuichudenglu: '退出登陸',
		queren: '確認',
		quxiao: '取消',
		genghuan: '查看',
		qingshuru: '請輸入',
		zanwujilv: '暫無記錄',
		lingquchenggong: '領取成功',
		baobiao_status: ['', '待審核', '成功', '拒絕'],
		yuan: '元',
		denglu_title: '請登陸',
		denglu_mas: '您的未登陸，無法進行操作，請您重新登錄'
	},
	kefu: {
		caozuozhinan: '操作指南',
		xianshangkefu: 'telegram客服',
		wx: '微信',
		tousudianhua: '投訴電話'
	},
	login: {
		username_placeholder: '請輸入賬號 *',
		username_alert: '請輸入賬號',
		password_placeholder: '請輸入密碼 *',
		password_alert: '請輸入密碼',
		password_alert_length: '密碼必須大於6位',
		wangjimima: '忘記密碼',
		zhuce: '立即註冊',
		denglu_btn: '登入',
		wangji: ['微信', '在線客服', "立即注冊", "遊客進入"]
	},
	zhuce: {
		top_right: '已有賬號，下載APP',
		username_placeholder: '請輸入賬號 *',
		username_alert: '請輸入賬號',
		password_placeholder: '請輸入密碼 *',
		password_alert: '請輸入密碼',
		password_alert_length: '密碼必須大於6位',
		password_alert_buyizhi: '兩次密碼不一致',
		password2_placeholder: '請再次輸入密碼 *',
		password2_alert: '請再次輸入密碼',
		qk_password_placeholder: '請輸入取款密碼 *',
		qk_password_alert: '請輸入取款密碼',
		qk_password_alert_length: '取款密碼必須大於6位',
		phone_placeholder: '請輸入手機號 *',
		phone_alert: '請輸入手機號',
		code_placeholder: '請輸入驗證碼 *',
		code_alert: '請輸入驗證碼',
		huoqu_btn: '獲取驗證碼',
		index: '返回首頁',
		zhuce: '註冊'
	},
	index: {
		top: ['英屬維京群島', '官方運營', '英屬維京群島', '（bvi）認證', '存款', '取款'],
		nav: ['首頁', '休閑區', '充值', '提現', '彩票', '電子', '視訊', '棋牌', '體育', '捕魚', '語言'],
		youxi: ['您需要為【show_title】轉入金額嗎?', '當前余額', '回收中...', '請輸入金額', '全部', '直接進入遊戲', '立即轉入遊戲']
	},
	bottom: {
		nav: ['活動中心', '線上客服', '首頁', '額度轉換', '我的'],
		navTow: ['福利', '客服', '首頁', '更多', '用戶戶口'],
	},
	gerenzhongxin: {
		xiugaimima: '修改密碼',
		lingqufuli: '領取每日福利',
		dailizhongxin: '代理中心',
		zhuzhanghuyue: '主賬戶余額',
		benyuexiazhuzonge: '下註總額',
		chongzhi: '充值',
		fanshui: '返水',
		tixian: '提現',
		xiaoxi: '消息',
		fulihuodong: '福利活動',
		baobiao: '報表',
		shishifanshui: '實時返水',
		shishifanshui_str: '自主洗碼,實時結算,請投註達標後再試',
		tikuanliushuizhi: "提款流水值",
		zhanghuyue: "賬戶余額",
		zijinhuishou: "資金回收",
		yinhanghukou: "銀行戶口",
		lishuxiaji: "隸屬下級",
		xiangxi: "詳細",
		qiandao: '簽到'
	},
	xiaoxi: {
		title: '消息中心',
		table: ['主題', '內容', '發送時間']
	},
	gerenxinxi: {
		title: '個人信息',
		bangdingshouji: '綁定手機',
		xiugaizhifumima: '修改支付密碼'
	},
	bangding: {
		title: '綁定手機',
		yibangding: '已綁定手機號',
		shoujihao: '手機號',
		yanzhengma: '驗證碼',
		jiumima: '舊密碼',
		xinmima: '新密碼',
		querenmima: '確認密碼'
	},
	jilv: {
		cunkuan: '存款',
		tixian: '提現',
		touzhu: '投註',
		fanshui: '返水',
		erdu: '額度轉換',
		riqi_title: '選擇日期',
		riqi: ['今日', '2天', '3天', '5天', '7天', '30天'],
		zongchenggongtixian: '總成功充值',
		tixianchenggong: '提現成功',
		cunkuan_tab: ['金額', '狀態', '時間'],
		touzhu_tab: ['玩法', '投註', '中獎金', '時間'],
		eduzhuanhuan_tab: ['金額', '类型', '時間']
	},
	erduzhuanhuan: {
		chonghuijinge: '重繪金額',
		zijinhuishou: '資金回收',
		zhongxinqianbao: '中心錢包',
		youxiqianbao: '遊戲錢包',
		zhuanzhang_placeholder: '請輸入轉帳金額',
		lijizhuanzhang: '立即轉帳',
		alt1: '兩邊遊戲ID不可一致',
		shengyu: '剩餘碼量:'
	},
	dailizhongxin: {
		tuiguanglianjie: '推廣鏈接',
		fuzhilianjie: '復製鏈接',
		fuzhichenggong: '復製成功 !',
		erweimatuiguang: '二維碼推廣',
		yongjinfafang: '傭金發放',
		huiyuangcunkuang: '會員存款',
		huiyuangtixian: '會員提現',
		huiyuan: '會員',
		huiyuanshuying: '會員輸贏',
		shenqinghuodong: '申請活動'
	},
	bangzhuzhongxin: {
		nav: ['活動中心', '客服中心', '額度轉換', '個人中心']
	}
}
